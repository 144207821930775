@font-face {
  font-family: KisSCCBT;
  font-weight: normal;
  font-style: normal;
  font-display: block;
  src: url("../fonts/KisSCCBT.eot");
  src: url("../fonts/KisSCCBT.eot?#iefix") format("embedded-opentype"),url("../fonts/KisSCCBT.woff") format("woff"),url("../fonts/KisSCCBT.woff2") format("woff2"),url("../fonts/KisSCCBT.ttf") format("truetype"),url("../fonts/KisSCCBT.svg#KisSCCBT") format("svg");
}

@font-face {
  font-family: Franklin Gothic;
  font-weight: 900;
  font-style: normal;
  font-display: block;
  src: url("../fonts/FRAHV.eot");
  src: url("../fonts/FRAHV.eot?#iefix") format("embedded-opentype"),url("../fonts/FRAHV.woff") format("woff"),url("../fonts/FRAHV.woff2") format("woff2"),url("../fonts/FRAHV.ttf") format("truetype"),url("../fonts/FRAHV.svg#Franklin Gothic") format("svg");
}

.seo-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: #303030;
  padding: 30px 0;
}

.seo-text ul {
  list-style: none;
  padding-left: 0;
}

.seo-text ul li {
  position: relative;
  padding-left: 40px;
  margin-bottom: 5px;
}

.seo-text ul li:before {
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  color: #32ac25;
  display: block;
  content: "\f00c";
  position: absolute;
  top: 4px;
  left: 0;
}

.why-order {
  padding: 30px 0;
}

.why-order .section-title {
  margin-top: 15px;
  margin-bottom: 15px;
}

.order-box {
  max-width: 560px;
  min-height: 140px;
  color: #303030;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 20px;
  margin: 25px auto 0;
  box-shadow: 0 0 13px 0 #d8d6d6;
  -webkit-box-shadow: 0 0 13px 0 #d8d6d6;
  -moz-box-shadow: 0 0 13px 0 #d8d6d6;
  overflow: hidden;
}

.order-box .icon {
  height: 46px;
  text-align: center;
  margin-bottom: 15px;
}

.order-box .title {
  min-height: 40px;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
}

.order-box p {
  min-height: 88px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
}

.main-intro .row ~ .row {
  padding-top: 30px;
  max-width: 360px;
  margin: 0 auto;
}

.main-intro .intro-backgroung {
  width: calc(100vw - 17px);
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  background-position: center;
  background-size: cover;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.main-intro .intro-content {
  text-align: center;
  position: relative;
  padding-top: 100px;
  padding-bottom: 50px;
}

.main-intro .intro-title {
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  color: #ffffff;
}

.main-intro .intro-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin-top: 30px;
}

.main-intro .button {
  width: 100%;
  max-width: 350px;
  height: 50px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25em;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 8px;
  background: #37af29;
  -webkit-box-shadow: 0 0 4px 0 #6d6d6d;
  box-shadow: 0 0 4px 0 #6d6d6d;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 30px auto 0;
}

.home-services {
  padding-top: 25px;
  padding-bottom: 50px;
}

.home-services-box {
  width: 100%;
  max-width: 280px;
  text-align: center;
  margin: 20px auto 0;
  display: block;
}

.home-services-box img {
  width: 100%;
  display: block;
  margin: 0 auto 20px;
}

.home-services-box .title {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #303030;
  padding-top: 10px;
  margin-bottom: 12px;
  border-top: 1px solid #d1eccd;
}

.examples-gallery {
  padding-top: 25px;
  padding-bottom: 25px;
}

.gallery-item {
  display: block;
  border-radius: 8px;
  background-color: #f7fff6;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 20px;
  box-shadow: 0 0 13px 0 #d8d6d6;
  -webkit-box-shadow: 0 0 13px 0 #d8d6d6;
  -moz-box-shadow: 0 0 13px 0 #d8d6d6;
}

.gallery-placeholder {
  display: block;
  width: 100%;
  height: 100%;
}

.our-offer {
  padding-top: 25px;
  padding-bottom: 35px;
}

.our-offer .row {
  margin-top: -20px;
}

.offer-box {
  max-width: 560px;
  text-align: center;
  border-radius: 8px;
  background-color: #0d8d65;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  padding: 30px;
  margin: 20px auto 0;
  box-shadow: 0 0 13px 0 #d8d6d6;
  -webkit-box-shadow: 0 0 13px 0 #d8d6d6;
  -moz-box-shadow: 0 0 13px 0 #d8d6d6;
}

.offer-icon {
  margin-bottom: 20px;
}

.offer-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  margin-bottom: 12px;
}

.offer-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}

.feature-schema {
  padding-top: 0px;
  padding-bottom: 60px;
}

.feature-schema .schema-list {
  max-width: 920px;
  list-style: none;
  padding: 0;
  margin: auto;
  position: relative;
}

.feature-schema .schema-list:before {
  content: '';
  border-left: 3px solid #37af29;
  position: absolute;
  top: 0px;
  left: 28px;
  bottom: 0px;
}

.feature-schema .schema-list li {
  position: relative;
  padding-left: 90px;
}

.feature-schema .schema-list li+li {
  margin-top: 50px;
}

.feature-schema .schema-list .number {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #37af29;
  box-sizing: border-box;
}

.feature-schema .schema-list .number span {
  width: 52px;
  height: 52px;
  font-weight: 900;
  font-size: 30px;
  line-height: 23px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #ffffff;
  box-sizing: border-box;
}

.feature-schema .schema-list .title {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #303030;
  margin-bottom: 5px;
}

.feature-intro {
  position: relative;
}

.feature-intro:before {
  width: calc(100vw - 17px);
  height: 100%;
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  background-image: url("../images/feature-intro-bg.png");
  background-position: center;
  background-size: cover;
  transform: translateX(-50%);
}

.feature-intro {
  position: relative;
}

.feature-intro:before {
  width: calc(100vw - 17px);
  height: 100%;
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  background-image: url("../images/feature-intro-bg.png");
  background-position: center;
  background-size: cover;
  transform: translateX(-50%);
}

.feature-intro .feature-intro-body {
  padding: 30px 0;
}

.feature-intro .v2 {
  max-width: 730px;
  margin: auto;
}

.feature-intro .v2 .section-title {
  font-size: 18px;
  color: #ffffff;
  position: relative;
}

.feature-intro .v2 .row {
  margin-top: -10px;
}

.feature-intro .v2 .row>div {
  margin-top: 10px;
}

.feature-intro .button {
  width: 100%;
  max-width: 350px;
  height: 50px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25em;
  color: #ffffff;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 8px;
  background: #37af29;
  -webkit-box-shadow: 0 0 4px 0 #6d6d6d;
  box-shadow: 0 0 4px 0 #6d6d6d;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.feature-intro-body div[id^="ocdw_form_builder-block"] {
  background: transparent;
  max-width: 410px;
  width: 100%;
  margin: 0 auto !important;
  min-height: 100px;
  position: relative;
}

.feature-intro-body div[id^="ocdw_form_builder-block"].static {
  border: 0;
  max-width: 740px;
}

.feature-intro-body div[id^="ocdw_form_builder-block"] .inner-header {
  background: transparent;
  font-family: "Ubuntu";
  font-size: 20px;
  color: #333;
  font-weight: 400;
  border-bottom: 1px solid transparent;
  padding: 15px 20px 0;
  position: relative;
  min-height: 50px;
  display: block;
  font-weight: 500;
  font-size: 30px;
  line-height: 1.5em;
  color: #ffffff;
  text-align: center;
  margin-bottom: 0px;
}

.feature-intro-body div[id^="ocdw_form_builder-block"] .inner-center {
  background: transparent;
  padding: 0 20px;
  overflow: auto;
  height: 100%;
}

.feature-intro-body div[id^="ocdw_form_builder-block"] .inner-center .additional-information {
  font-family: "Ubuntu";
  font-size: 16px;
  line-height: 1.5em;
  text-align: center;
  color: #ffffff;
  margin: 0 0 0 !important;
}

.feature-intro-body div[id^="ocdw_form_builder-block"] .inner-center input[type=text]:not(.numInput),
.feature-intro-body div[id^="ocdw_form_builder-block"] .inner-center input[type=email],
.feature-intro-body div[id^="ocdw_form_builder-block"] .inner-center input[type=tel],
.feature-intro-body div[id^="ocdw_form_builder-block"] .inner-center select:not(.flatpickr-monthDropdown-months):not(.oc15-fix) {
  width: 100%;
  height: 50px;
  min-height: 34px;
  display: block;
  min-height: 50px;
  font-family: "Ubuntu";
  font-size: 16px;
  line-height: 1.25em;
  color: #333;
  padding: 15px;
  margin: 0 0 5px;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  position: relative;
  -webkit-box-shadow: 0 0 4px 0 #6d6d6d;
  box-shadow: 0 0 4px 0 #6d6d6d;
  -webkit-transition: border-color .75s;
  -moz-transition: border-color .75s;
  -o-transition: border-color .75s;
  transition: border-color .75s;
  box-sizing: border-box;
  outline: none;
}

.feature-intro-body div[id^="ocdw_form_builder-block"] .inner-footer {
  padding: 15px 20px;
  background: transparent;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-top: 1px solid transparent;
  width: 100%;
  height: 100%;
}

.feature-intro-body div[id^="ocdw_form_builder-block"] .inner-footer .save-form {
  width: 100%;
  max-width: 350px;
  height: 50px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25em;
  border-radius: 8px;
  background: #37af29;
  -webkit-box-shadow: 0 0 4px 0 #6d6d6d;
  box-shadow: 0 0 4px 0 #6d6d6d;
  box-sizing: border-box;
}

.section-feature__body {
  max-width: 360px;
  font-weight: 500;
  line-height: 1.25em;
  color: #fff;
  text-align: center;
  padding: 50px 0;
  position: relative;
  margin: auto;
}

.section-feature__body h2 {
  font-weight: 500;
  font-size: 30px;
  line-height: 1.5em;
  margin-bottom: 0px;
}

.section-feature__body p {
  font-size: 16px;
  line-height: 1.5em;
}

.section-feature__body button {
  width: 100%;
  max-width: 480px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25em;
  color: #fff;
  display: block;
  background-color: #0cb9e3;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 0 4px 0 #6d6d6d;
  padding: 15px;
  margin: 30px auto 0;
}

.content-area {
  font-family: "Ubuntu";
  font-weight: 400;
}

.content-area .row {
  margin-right: -15px;
  margin-left: -15px;
}

.section-title {
  font-weight: 500;
  font-size: 30px;
  line-height: 1.25em;
  text-align: center;
  margin-bottom: 30px;
}

.section-intro {
  margin-top: 20px;
}

.intro-banner {
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 15px;
  position: relative;
  overflow: hidden;
}

.intro-banner:before {
  width: 100%;
  height: 100%;
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: .70;
}

.intro-banner>div {
  position: relative;
  z-index: 1;
}

.intro-banner__content {
  height: 100%;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 50px 0 20px;
}

.intro-banner__title {
  font-weight: 700;
  font-size: 26px;
  line-height: 38px;
}

.intro-banner__text {
  font-size: 16px;
  line-height: 20px;
  margin-top: 15px;
}

.intro-banner__form {
  height: 100%;
  margin-left: auto;
  padding: 20px 10px 30px;
}

.intro-banner__form-inner {
  min-height: 485px;
  max-width: 485px;
  text-align: center;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 32px;
  margin: auto;
}

.intro-form__title {
  font-weight: 500;
  font-size: 30px;
  line-height: 1.25em;
  text-align: center;
  margin-bottom: 5px;
}

.intro-form__desc {
  font-size: 16px;
  line-height: 1.25em;
}

.intro-form__desc+.intro-form__desc {
  margin-top: 15px;
}

.intro-form__tel {
  font-weight: 700;
  font-size: 22px;
  line-height: 1.25em;
  color: #0cb9e3;
  border-bottom: 1px solid #0cb9e3;
}

.intro-form__tel:hover {
  color: #176697;
  border-bottom: 1px solid #176697;
}

.icon-box {
  max-width: 360px;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 20px;
  margin: 20px auto 0;
  box-shadow: 0 0 13px 0 #d8d6d6;
  -webkit-box-shadow: 0 0 13px 0 #d8d6d6;
  -moz-box-shadow: 0 0 13px 0 #d8d6d6;
}

.icon-box__header {
  min-height: 40px;
  display: flex;
  align-items: center;
}

.icon-box__icon {
  height: 26px;
}

.icon-box__icon img {
  width: 100%;
  height: 26px;
}

.icon-box__icon ~ .icon-box__title {
  margin-left: 15px;
}

.icon-box__title {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.icon-box__text {
  font-size: 14px;
  line-height: 18px;
  overflow: hidden;
}

.intro-form__form {
  padding-top: 25px;
}

.intro-form__form div[id^="ocdw_form_builder-block"].static {
  border: 1px solid #ffffff;
  max-width: 100%;
}

.intro-form__form div[id^="ocdw_form_builder-block"] .inner-header {
  display: none;
  background-color: #ffffff;
}

.intro-form__form div[id^="ocdw_form_builder-block"] .inner-center {
  padding: 5px 0px;
}

.intro-form__form div[id^="ocdw_form_builder-block"] .inner-center .field-heading {
  width: 100%;
  font-family: "Ubuntu";
  font-weight: 700;
  font-size: 16px;
  line-height: 1.25em;
  text-align: left;
  color: #666;
  display: block;
  position: relative;
}

.intro-form__form div[id^="ocdw_form_builder-block"] .inner-center .inner-fields {
  position: relative;
  display: block;
  margin: 0px 0 0;
  overflow: hidden;
}

.intro-form__form div[id^="ocdw_form_builder-block"] .inner-center .inner-fields>div {
  margin-left: 0;
  margin-right: 0;
}

.intro-form__form div[id^="ocdw_form_builder-block"] .inner-center .inner-fields [data-field-type="telephone"] {
  margin-bottom: 30px;
}

.intro-form__form div[id^="ocdw_form_builder-block"] .inner-center input[type=tel] {
  width: 100%;
  min-height: 50px;
  font-family: "Ubuntu";
  font-size: 16px;
  line-height: 1.25em;
  color: #333;
  border-radius: 8px;
  background: #ededed;
  border: 1px solid #ededed;
  margin: 0 0 5px;
  padding: 15px;
}

.intro-form__form div[id^="ocdw_form_builder-block"] .upload-file-button {
  min-width: 100px;
  height: 50px;
  font-family: "Ubuntu";
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25em;
  padding: 0 15px;
  text-decoration: none;
  color: #fff;
  background-color: #176697;
  text-align: center;
  cursor: pointer;
  border-radius: 8px;
}

.intro-form__form div[id^="ocdw_form_builder-block"] .inner-footer {
  padding: 0;
  background: #ffffff;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #ffffff;
  width: 100%;
  height: 100%;
}

.intro-form__form div[id^="ocdw_form_builder-block"] .inner-footer .save-form {
  width: 100%;
  max-width: 100%;
  height: 50px;
  font-family: "Ubuntu";
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25em;
  color: #fff;
  display: block;
  border: 0;
  border-radius: 8px;
  background: #0cb9e3;
  -webkit-box-shadow: 0 0 4px 0 #6d6d6d;
  box-shadow: 0 0 4px 0 #6d6d6d;
  padding: 15px;
}

.intro-form__form div[id^="ocdw_form_builder-block"] .inner-footer .save-form:hover {
  background: #2980b9;
}

#ocdw_form_builder-block-4 {
  border-radius: 8px;
  overflow: hidden;
}

#ocdw_form_builder-block-4 .inner-header {
  background-color: #ffffff;
  text-align: center;
}

#ocdw_form_builder-block-4 .inner-center {
  padding: 5px 20px;
}

#ocdw_form_builder-block-4 .inner-center .field-heading {
  width: 100%;
  font-family: "Ubuntu";
  font-weight: 700;
  font-size: 16px;
  line-height: 1.25em;
  text-align: left;
  color: #666;
  display: block;
  position: relative;
}

#ocdw_form_builder-block-4 .inner-center .inner-fields {
  position: relative;
  display: block;
  margin: 0px 0 0;
  overflow: hidden;
}

#ocdw_form_builder-block-4 .inner-center .inner-fields>div {
  margin-left: 0;
  margin-right: 0;
}

#ocdw_form_builder-block-4 .inner-center .inner-fields [data-field-type="telephone"] {
  margin-bottom: 30px;
}

#ocdw_form_builder-block-4 .inner-center input[type=tel] {
  width: 100%;
  min-height: 50px;
  font-family: "Ubuntu";
  font-size: 16px;
  line-height: 1.25em;
  color: #333;
  border-radius: 8px;
  background: #ededed;
  border: 1px solid #ededed;
  margin: 0 0 5px;
  padding: 15px;
}

#ocdw_form_builder-block-4 .inner-center .upload-file-button {
  min-width: 100px;
  height: 50px;
  font-family: "Ubuntu";
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25em;
  padding: 0 15px;
  text-decoration: none;
  color: #fff;
  background-color: #176697;
  text-align: center;
  cursor: pointer;
  border-radius: 8px;
}

#ocdw_form_builder-block-4 .inner-footer {
  padding: 0 20px 20px;
  background: #ffffff;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #ffffff;
  width: 100%;
  height: 100%;
}

#ocdw_form_builder-block-4 .inner-footer .close-modal {
  display: none;
}

#ocdw_form_builder-block-4 .inner-footer .save-form {
  width: 100%;
  max-width: 100%;
  height: 50px;
  font-family: "Ubuntu";
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25em;
  color: #fff;
  display: block;
  border: 0;
  border-radius: 8px;
  background: #0cb9e3;
  -webkit-box-shadow: 0 0 4px 0 #6d6d6d;
  box-shadow: 0 0 4px 0 #6d6d6d;
  padding: 15px;
}

#ocdw_form_builder-block-4 .inner-footer .save-form:hover {
  background: #2980b9;
}

.section-price {
  padding: 40px 0;
}

.section-price__body,
.section-price__footer {
  width: 100%;
  max-width: 1000px;
  margin: auto;
}

.price-table {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 0 8px 0 #d2d2d2;
  border-collapse: collapse;
  overflow: hidden;
}

.price-table th {
  font-size: 13px;
  line-height: 1.25em;
  color: #fff;
  text-align: center;
  background-color: #176697;
  padding: 15px 5px;
  border-right: 1px solid #dce8f0;
}

.price-table td {
  font-weight: 400;
  font-size: 13px;
  line-height: 1.25em;
  color: #4b4b4b;
  text-align: center;
  padding: 10px 5px;
  border-top: 1px solid #dce8f0;
  border-right: 1px solid #dce8f0;
  border-left: 1px solid #dce8f0;
}

.button-primary,
.button-secondary {
  width: 100%;
  max-width: 480px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25em;
  color: #fff;
  display: block;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 0 4px 0 #6d6d6d;
  padding: 15px;
  margin: 30px auto 0;
  outline: none;
}

.button-primary {
  background-color: #176697;
}

.button-secondary {
  background-color: #0cb9e3;
}

.section-portfolio__list {
  margin-top: -10px;
}

.section-portfolio__item {
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 0 13px 0 #d8d6d6;
  -webkit-box-shadow: 0 0 13px 0 #d8d6d6;
  -moz-box-shadow: 0 0 13px 0 #d8d6d6;
  overflow: hidden;
  margin-top: 20px;
}

.section-portfolio__image {
  width: 100%;
  height: auto;
  display: block;
}

.section-steps {
  padding-top: 40px;
  padding-bottom: 80px;
}

.step__item {
  width: 85%;
  max-width: 360px;
  height: 100%;
  min-height: 200px;
  color: #ffffff;
  background-color: #0cb9e3;
  position: relative;
  padding: 40px 70px;
  margin: 20px 0 0 auto;
  border-radius: 10px;
  box-shadow: 0 0 13px 0 #d8d6d6;
  -webkit-box-shadow: 0 0 13px 0 #d8d6d6;
  -moz-box-shadow: 0 0 13px 0 #d8d6d6;
}

.step__number {
  font-family: "Franklin Gothic";
  font-size: 180px;
  color: #acefff;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  text-shadow: 0 0 13px #d8d6d6;
}

.step__icon path {
  fill: #fff;
}

.step__title {
  font-weight: 600;
  font-size: 18px;
  line-height: 2em;
}

.step__description {
  height: 72px;
  font-size: 16px;
  line-height: 24px;
  overflow: hidden;
}

.section-advantages,
.section-services {
  padding: 20px 0;
}

.section-advantages__body,
.section-services__body,
.section-manufacture__body {
  margin-top: -20px;
}

.service-box {
  max-width: 580px;
  display: block;
  border-radius: 10px;
  background-color: #ffffff;
  margin-top: 20px;
  box-shadow: 0 0 13px 0 #d8d6d6;
  -webkit-box-shadow: 0 0 13px 0 #d8d6d6;
  -moz-box-shadow: 0 0 13px 0 #d8d6d6;
  overflow: hidden;
  position: relative;
  margin: 20px auto;
}

.service-box__image {
  position: relative;
}

.service-box__image:after {
  width: 100%;
  height: 100%;
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: .35;
}

.service-box__image div {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.service-box__placeholder {
  width: 100%;
  height: auto;
  display: block;
}

.service-box__title {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.25em;
  color: #fff;
  position: absolute;
  bottom: 25px;
  left: 25px;
}

.section-manufacture {
  padding: 20px 0 50px;
}

.feature-box {
  max-width: 560px;
  min-height: 140px;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 20px;
  margin: 25px auto 0;
  box-shadow: 0 0 13px 0 #d8d6d6;
  -webkit-box-shadow: 0 0 13px 0 #d8d6d6;
  -moz-box-shadow: 0 0 13px 0 #d8d6d6;
  overflow: hidden;
}

.feature-box__icon {
  height: 46px;
  text-align: center;
  margin-bottom: 15px;
}

.feature-box__text {
  min-height: 40px;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
}

.section-feature {
  position: relative;
}

.section-feature:before {
  width: calc(100vw - 17px);
  height: 100%;
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  background-color: #176697;
  transform: translateX(-50%);
}

.section-feature__body {
  max-width: 360px;
  font-weight: 500;
  line-height: 1.25em;
  color: #fff;
  text-align: center;
  padding: 50px 0;
  position: relative;
  margin: auto;
}

.section-feature__body h2 {
  font-weight: 500;
  font-size: 30px;
  line-height: 1.5em;
  margin-bottom: 0px;
}

.section-feature__body p {
  font-size: 16px;
  line-height: 1.5em;
}

.section-feature__body button {
  width: 100%;
  max-width: 480px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25em;
  color: #fff;
  display: block;
  background-color: #0cb9e3;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 0 4px 0 #6d6d6d;
  padding: 15px;
  margin: 30px auto 0;
}

.section-contacts {
  padding-top: 40px;
  padding-bottom: 50px;
}

.section-contacts__title {
  width: 100%;
  font-weight: 500;
  font-size: 30px;
  line-height: 1.25em;
  text-align: center;
  display: block;
  margin-bottom: 0;
}

.section-contacts__list {
  width: 100%;
}

.section-contacts__phones,
.section-contacts__mail,
.section-contacts__address {
  min-height: 105px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  background-color: #ffffff;
  margin-top: 30px;
  box-shadow: 0 0 13px 0 #d8d6d6;
  -webkit-box-shadow: 0 0 13px 0 #d8d6d6;
  -moz-box-shadow: 0 0 13px 0 #d8d6d6;
}

.section-contacts__phones a,
.section-contacts__mail a,
.section-contacts__address a {
  border-bottom: 1px solid #cbcbcb;
}

.section-contacts__phones a:hover,
.section-contacts__mail a:hover,
.section-contacts__address a:hover {
  color: #45c2eb;
  border-bottom: 1px solid #45c2eb;
}

.section-contacts__phones ul {
  padding: 0;
  list-style: none;
}

.section-contacts__phones li+li {
  margin-top: 8px;
}

.section-contacts__icon {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid #45c2eb;
  margin: 0 auto 20px;
}

.section-contacts__map {
  min-height: 415px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  border-radius: 10px;
  background-color: #ffffff;
  margin-top: 30px;
  box-shadow: 0 0 13px 0 #d8d6d6;
  -webkit-box-shadow: 0 0 13px 0 #d8d6d6;
  -moz-box-shadow: 0 0 13px 0 #d8d6d6;
}

.section-contacts__map iframe {
  width: 100%;
  min-height: 415px;
  display: block;
  border: 0;
}

#popup-default-178 {
  padding: 0;
  border-radius: 8px;
}

#popup-default-178 .popup-heading {
  padding: 0;
}

.calculator {
  width: 100%;
  max-width: 485px;
  padding: 30px 0;
  background-color: #176697;
  border-radius: 8px;
  box-sizing: border-box;
}

.calculator-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.5em;
  color: #ffffff;
  text-align: center;
  margin-bottom: 15px;
}

.calculator-item {
  padding: 0 30px;
}

.calculator-item label {
  display: block;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.5em;
  color: #ffffff;
}

.calculator-item select,
.calculator-item input[type="number"] {
  width: 100%;
  height: 44px;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5em;
  display: block;
  border: 0;
  border-radius: 6px;
  padding: 10px;
  outline: none;
}

.calculator-item+.calculator-item {
  padding: 10px 30px;
}

.calculator-result {
  font-weight: 400;
  font-size: 20px;
  line-height: 1.5em;
  color: #ffffff;
  text-align: center;
  padding: 8px 30px;
  border-top: 1px solid #0cb9e3;
  border-bottom: 1px solid #0cb9e3;
  margin-top: 10px;
}

.calculator-result span {
  padding: 0 4px;
}

.calculator-action {
  padding: 0 30px;
}

.calculator-action button {
  margin-top: 30px;
  outline: none;
}

.section-estimation {
  padding: 50px 0;
}

.estimation-item {
  max-width: 100%;
  position: relative;
  padding: 45px 30px 15px;
  margin: 50px 0;
  border-bottom: 20px solid #0cb9e3;
  border-radius: 8px;
  box-sizing: border-box;
  overflow: visible;
}

.estimation-item .title {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #0cb9e3;
  margin-bottom: 10px;
}

.estimation-item .number {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 30px;
  background-color: #0cb9e3;
  transform: translateY(-50%);
  box-sizing: border-box;
}

.estimation-item .number span {
  width: 52px;
  height: 52px;
  font-weight: 900;
  font-size: 30px;
  line-height: 23px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #ffffff;
  box-sizing: border-box;
}

.section-clients {
  padding-bottom: 50px;
}

.section-clients .section-title {
  margin-top: 0;
  margin-bottom: 15px;
}

.client-item {
  border-radius: 8px;
  background-color: #ffffff;
  margin: 25px auto 0;
  box-shadow: 0 0 13px 0 #d8d6d6;
  -webkit-box-shadow: 0 0 13px 0 #d8d6d6;
  -moz-box-shadow: 0 0 13px 0 #d8d6d6;
  overflow: hidden;
}

.client-item .image ~ .client-description {
  min-height: 145px;
}

.client-item .image-wrap {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.client-item .image-placeholder {
  width: 100%;
  height: 100%;
  display: block;
}

.client-item .client-description {
  min-height: 140px;
  font-size: 14px;
  line-height: 20px;
  color: #4b4b4b;
  padding: 20px;
}

.client-item .title {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #4b4b4b;
  margin-bottom: 5px;
}

.client-item p {
  margin: 0;
}

@media screen and (min-width: 576px) {
  .feature-intro .feature-intro-body {
    padding: 50px 50px 50px;
  }

  .feature-intro .v2 .section-title {
    font-size: 30px;
  }

  .section-feature__body {
    max-width: 100%;
  }

  .section-title {
    font-size: 36px;
  }

  .intro-banner__content {
    padding: 50px 20px 20px;
  }

  .intro-banner__form {
    padding: 20px 10px 75px;
  }

  .intro-form__title {
    font-size: 36px;
  }

  .icon-box {
    max-width: 100%;
  }

  .price-table th {
    font-size: 16px;
  }

  .price-table td {
    font-size: 16px;
  }

  .step__item {
    margin: 20px auto 0;
  }

  .section-feature__body {
    max-width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .main-intro .row ~ .row {
    max-width: 100%;
  }

  .main-intro .intro-content {
    padding-top: 150px;
    padding-bottom: 150px;
  }

  .main-intro .intro-title {
    font-size: 50px;
    line-height: 58px;
  }

  .main-intro .intro-text {
    font-size: 20px;
    line-height: 27px;
  }

  .intro-banner {
    padding: 75px 35px;
  }

  .section-contacts__phones {
    min-height: 240px;
  }

  .section-contacts__phones .section-contacts__icon {
    margin: 0 auto 20px;
  }

  .section-contacts__mail,
  .section-contacts__address {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .section-contacts__icon {
    margin: 0 20px 0 0;
  }
}

@media screen and (min-width: 992px) {
  .feature-intro .feature-intro-body {
    padding: 50px 50px 50px;
  }

  .section-feature__body {
    padding: 45px 50px 60px;
  }

  .section-title {
    font-size: 30px;
  }

  .intro-banner {
    padding: 75px 70px;
  }

  .intro-banner__content {
    text-align: left;
    padding: 75px 0;
  }

  .intro-banner__title {
    font-size: 36px;
    line-height: 46px;
  }

  .intro-banner__text {
    font-size: 18px;
    line-height: 26px;
  }

  .intro-banner__form {
    padding: 0;
  }

  .intro-form__title {
    font-size: 30px;
  }

  .button-primary,
  .button-secondary {
    max-width: 100%;
  }

  .step__item {
    width: 90%;
    padding: 30px 20px 20px 70px;
    margin: 20px 0 0 auto;
  }

  .step__description {
    font-size: 14px;
    line-height: 24px;
  }

  .service-box__title {
    font-size: 24px;
  }

  .section-feature.v2 .section-feature__body {
    padding: 50px 50px 80px;
  }

  .section-feature__body {
    padding: 45px 50px 60px;
  }

  .section-contacts__area {
    padding-top: 45px;
  }

  .estimation-item {
    min-height: 440px;
  }
}

@media screen and (min-width: 1200px) {
  .main-intro .row ~ .row {
    margin: 0 auto;
    padding-top: 0;
    transform: translateY(calc(-50% - 10px));
  }

  .feature-intro:after {
    width: 420px;
    height: 195px;
    content: '';
    display: block;
    background-image: url("feature-decor.png");
    background-position: center;
    background-size: contain;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
  }

  .feature-intro:after {
    width: 420px;
    height: 195px;
    content: '';
    display: block;
    background-image: url("feature-decor.png");
    background-position: center;
    background-size: contain;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
  }

  .intro-banner__content {
    padding: 10px 0 0;
  }

  .intro-banner__title {
    font-size: 46px;
    line-height: 58px;
    margin-bottom: 50px;
  }

  .icon-box {
    padding: 15px 15px 15px 20px;
  }

  .step__description {
    min-height: 58px;
    font-size: 14px;
    line-height: 18px;
  }

  .section-feature:after {
    display: block;
    content: url("feature-decor.png");
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
  }

  .section-feature.v2:after {
    display: block;
    content: url("feature-decor2.png");
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-20%, 0%);
  }

  .estimation-item {
    min-height: 360px;
    margin: 50px 0 0;
  }

  .client-item .client-description {
    min-height: 94px;
  }
}